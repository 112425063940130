.price-and-lock-container{
    margin-top: -18rem;
}

.price-and-lock-title{
    text-align: center;
    color: #FFC134;
    font-weight: 700;
    margin-bottom: 2rem;
}

table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px -10px 25px 5px rgba(0, 0, 0, 0.10);
    background-color: #ffffff;
  }
  
  th, td {
    border: 1px solid #000000;
    text-align: center;
    padding: 8px;
    font-size: 13px !important;
  }
  
  th {
    background-color: #ff5c00;
    color: #ffffff;
    font-weight: 500;
  }

  td {
    background-color:#ffffff;
    color: #000000;
    font-weight: 500;
  }
  

  @media screen and (max-width:460px) {
   td {
    word-wrap: break-word;
    font-size: 11px !important;
   }
   th{
    font-size: 11px !important;
   }
   table {
    max-width: 100%;
    table-layout: fixed;
  }  
  }

  @media screen and (max-width: 991.7px) {
    .price-and-lock-container{
      margin-top: -54rem;
  }
   }
 

.discliamer{
  box-shadow: 0px 10px 25px 5px rgba(0, 0, 0, 0.10);
  padding: 2rem;
  background-color: #ffffff;
  border-bottom-left-radius: 26px;
  border-bottom-right-radius: 26px;
  font-size: 12px;
  COLOR: #000000;
}

.cut-off-link{
  text-decoration: underline;
  color: #FF5C00 !important;
}

@media screen and (min-width: 767px) and (max-width: 991.9px) {
  .price-and-lock-container{
      margin-top: -30rem !important;
  } 
  .header-bg-price-and-lock{ 
    padding-bottom: 35rem !important;
}
}

@media screen and (min-width: 992px) {
  .header-bg-price-and-lock{
      background-image: url("https://stagingresources.swmc.com/swmc-images/capital-market/glossary-bg-desktop.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height:auto;
      padding-top: 11rem;
      padding-bottom: 19rem;
  }
}

@media screen and (max-width: 991.9px) {
  .header-bg-price-and-lock{
      background-image: url("https://stagingresources.swmc.com/swmc-images/capital-market/glossary-bg-mobile.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-top: 5rem;  
      padding-bottom: 56rem;
  }
}