/* Glossary.css */

/* Styling for the container holding the alphabet buttons */
.alphabet {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .alphabet-col{
    display: block;
    margin-left:auto;
    margin-right:auto;
    background-color: #ffffff;
    border: 2px solid #FF541C;
    border-radius: 10px;
    padding: 10px 20px;
  }
  .glossary-col{
    display: block;
    margin-left:auto;
    margin-right:auto;
    text-align: justify;
    hyphens: auto;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
    padding: 3rem 4rem;
    margin-top: 2rem;
  }
  
  /* Styling for individual alphabet buttons */
  .alphabet button {
    background: none; /* No background for the button itself */
    color: #083F88;
    font-weight: 500;
    border: none;
    font-size: 19px;
    margin: 5px;
    background-color: white;
    cursor: pointer;
  }
  /* Styling for the selected alphabet button */
  .alphabet button.active {
    background-color: #e74c3c;
  }
  
  /* Styling for the terms */
  .terms {
    margin-top: 20px;
  }
  
  .terms div {
    margin-bottom: 10px;
  }
  
  /* Styling for the list of glossary terms */
.terms ul {
    list-style: none;
    padding: 0;
  }
  
  /* Styling for list items with custom bullet images */
  .terms li {
    margin-bottom: 20px;
    position: relative;
    margin-left: 20px;
    font-size: 15px;
    border-bottom: 2px solid #8899A4;
    padding-bottom: 15px;
  }

  .terms li:last-child {
    border-bottom: none;
  }

  .custom-bullet::before {
    content: "";
    width: 30px;
    height: 30px;
    background-image: url('https://stagingresources.swmc.com/swmc-images/capital-market/glossary-list-desktop.webp') ;
    background-size: cover;
    display: inline-block;
    margin-right: 5px;
    position: absolute;
    left: -50px; /* Adjust the spacing as needed */
    top: 3px;
  }
  
  /* Styling for "Read More" links */
  .read-more {
    cursor: pointer;
    color: blue; /* You can choose a color that matches your design */
    text-decoration: underline;
  }
  .definition-text {
    font-size: 15px; /* Adjust the font size as needed */
    color:  #000000;
    font-weight: 400;
  }
  

  @media screen and (min-width: 1200px) {
    .alphabet button {
        font-size: 19px;
        margin: 6px;
      }
  }

  #defination-array{
    border-bottom: none !important;
    list-style: disc;
    background-image: none;
    margin-bottom: 0px !important;
    margin-top:-10px;
  }

  .first-definition {
    border-bottom: none !important; 
    margin-left: 0px !important;
    margin-bottom: 0px !important;
  }
 
  .active-letter {
    font-weight: 700 !important;
    color: #FF541C !important;

    /* text-decoration: underline; */
  }

  .glossary-text{
    text-align: center;
    color: #FFC134;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  @media screen and (min-width: 992px) {
    .glossary-component{
      margin-top: -24.5rem;
    }
    .term-detail-container{
      margin-top: -24.5rem;
      margin-bottom: 11rem;
    }
  }

  @media screen and (max-width: 991.8px) {
    .glossary-component{
      margin-top: -24.5rem;
    }
    .alphabet button {
      font-weight: 500;
      border: none;
      font-size: 12px;
      margin: 2px;
    }
    .alphabet {
      display: flex;
      flex-wrap: wrap;
      gap: 1px;
    }
    .alphabet-col{
      padding: 10px 5px
    }
    .glossary-col{
      padding: 1rem 2.5rem;
      margin-top: 2rem;
      hyphens: none;
    }
    .glossary-text{
      font-size: 2.5rem;
    }
    .term-detail-container{
      margin-top: -24.5rem;
      margin-bottom: 6rem;
    }
  }

  @media screen and (min-width: 389px) and (max-width: 400px) {
    .alphabet-col{
      padding: 10px 10px
    }
  }

  @media screen and (min-width: 401px) and (max-width: 450px) {
    .alphabet-col{
      padding: 10px 10px
    }
    .alphabet {
      gap: 3px;
    }
  }

  @media screen and (min-width: 440px) and (max-width: 480px) {
    .alphabet {
      gap: 5px;
    }
  }

  
  @media screen and (min-width: 480px) and (max-width: 576px) {
    .alphabet {
      gap: 8px;
    }
    .alphabet button {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 767.7px) {
    .alphabet {
      gap: 10px;
    }
    .alphabet-col{
      padding: 10px 14px
    }
    .alphabet button {
      font-size: 14px;
    }
    .term-detail-container{
      margin-top: -22.5rem;
      margin-bottom: 8rem;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 991.7px) {
    .alphabet {
      gap: 10px;
    }
    .alphabet-col{
      padding: 10px 12px
    }
    .alphabet button {
      font-size: 14px;
    }
    .term-detail-container{
      margin-top: -21.5rem;
      margin-bottom: 7rem;
    }
  }
   

  /* CSS for Background */

  @media screen and (min-width: 992px) {
    .header-bg-glossary{
        background-image: url("https://stagingresources.swmc.com/swmc-images/capital-market/glossary-bg-desktop.webp");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height:auto;
        padding-top: 11rem;
        padding-bottom: 29rem;
    }
  }

  @media screen and (max-width: 991.9px) {
    .header-bg-glossary{
        background-image: url("https://stagingresources.swmc.com/swmc-images/capital-market/glossary-bg-mobile.webp");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-top: 5rem;  
        padding-bottom: 28rem;
    }
  }
  