.usda-program-container{
    margin-top: -18.5rem;
}

.usda-program-row{
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 26px;
    box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.10);
}

.usda-features{
    color: #FF541C;
    font-weight: 600;
    font-size: 18px;
}

.usda-requirements{
    color: #FF541C;
    font-weight: 600;
    font-size: 18px;
}

.usda-col-1{
    display: block;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}
.usda-col-2{
    display: block;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}
  
.usda1-ul{
    margin-top: 1rem;
    margin-bottom:1rem;
}
.usda2-ul{
    margin-top: 1rem;
    margin-bottom:1rem;
}

.usda1-ul li{
    margin-top: 0.75rem;
    color: #083F88;
    font-size: 17px;
    font-weight: 400;
}

.usda2-ul li{
    margin-top: 0.75rem;
    color: #083F88;
    font-size: 17px;
    font-weight: 400;
}

.note-section{
    border: 1px solid #000000;
    padding: 16px;
    margin-top: 2rem;
    font-size: 14px;
    margin-bottom: 2rem;
}
.note-section a{
    color: #000000;
    text-decoration: underline;
}

.usda-title{
    text-align: center;
    color: #FFC134;
    font-weight: 700;
    margin-bottom: 2rem;
}

@media screen and (max-width: 991.7px){
   .usda-program-container{
    margin-top: -23rem;
   } 

   .usda-features{
    margin-top: 1rem;
   }

   .usda-program-row{
    width: 95%;
    display: block;
    margin-left: auto;
    margin-right: auto;
   }
   .usda-program-row{
    padding: 7px;
   }
   .note-section{
    margin-top: 2rem;
    font-size: 14px;
    margin-bottom: 2rem;
}
}

@media screen and (min-width:767.7px) and (max-width: 991.7px){
   
    .usda-program-row{
     width: 75%;
    }
 }

 .note-col{
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/* css for Background */

@media screen and (min-width: 992px) {
    .header-bg-usda-program{
        background-image: url("https://stagingresources.swmc.com/swmc-images/capital-market/glossary-bg-desktop.webp");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height:auto;
        padding-top: 11rem;
        padding-bottom: 19rem;
    }
}

@media screen and (max-width: 991.9px) {
    .header-bg-usda-program{
        background-image: url("https://stagingresources.swmc.com/swmc-images/capital-market/glossary-bg-mobile.webp");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding-top: 5rem;  
        padding-bottom: 26rem;
    }
}