/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,100&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


body .uwy.userway_p3 .uai,
body .uwy.userway_p6 .uai,
body .uwy.userway_p7 .uai, 
body .uwy.userway_p8 .uai {
    top: 25px !important;
    left: 450px !important;
}

@media screen and (max-width: 500.7px) {
    body .uwy .uai,
    .uwy .uai img:not(.check_on), body .uwy .uai img:not(.check_on) {
        width: 30px !important;
        height: 30px !important;
        min-width: 30px !important;
        min-height: 30px !important;
        max-width: 30px !important;
        max-height: 30px !important;
        padding: 2px !important;
    }
}

@media screen and (min-width:320px) and (max-width:330.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 270px !important;
    }
}

@media screen and (min-width:331px) and (max-width:340.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 275px !important;
    }
}

@media screen and (min-width:341px) and (max-width:350.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 280px !important;
    }
}

@media screen and (min-width:351px) and (max-width:360.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 285px !important;
    }
}

@media screen and (min-width:361px) and (max-width:370.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 290px !important;
    }
}

@media screen and (min-width:371px) and (max-width:380.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 280px !important;
    }
}

@media screen and (min-width:381px) and (max-width:390.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 285px !important;
    }
}

@media screen and (min-width:391px) and (max-width:400.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 290px !important;
    }
}

@media screen and (min-width:401px) and (max-width:410.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
      top: 25px !important;
        left: 290px !important;
    }
}

@media screen and (min-width:411px) and (max-width:420.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 290px !important;
    }
}

@media screen and (min-width:421px) and (max-width:430.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 295px !important;
    }
}

@media screen and (min-width:431px) and (max-width:440.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 295px !important;
    }
}

@media screen and (min-width:441px) and (max-width:450.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 295px !important;
    }
}

@media screen and (min-width:451px) and (max-width:460.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 300px !important;
    }
}

@media screen and (min-width:461px) and (max-width:470.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 300px !important;
    }
}

@media screen and (min-width:471px) and (max-width:480.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 305px !important;
    }
}

@media screen and (min-width:481px) and (max-width:490.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 305px !important;
    }
}

@media screen and (min-width:491px) and (max-width:500.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 310px !important;
    }
}

@media screen and (min-width:501px) and (max-width:530.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 310px !important;
    }
}

@media screen and (min-width:531px) and (max-width:550.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 25px !important;
        left: 315px !important;
    }
}

@media screen and (min-width:551px) and (max-width:560.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 320px !important;
    }
}

@media screen and (min-width:561px) and (max-width:570.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 320px !important;
    }
}

@media screen and (min-width:571px) and (max-width:580.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 325px !important;
    }
}

@media screen and (min-width:581px) and (max-width:590.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 325px !important;
    }
}

@media screen and (min-width:591px) and (max-width:600.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:601px) and (max-width:610.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:611px) and (max-width:620.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 335px !important;
    }
}

@media screen and (min-width:621px) and (max-width:630.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 320px !important;
    }
}

@media screen and (min-width:631px) and (max-width:640.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 325px !important;
    }
}

@media screen and (min-width:641px) and (max-width:650.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:651px) and (max-width:660.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 335px !important;
    }
}

@media screen and (min-width:661px) and (max-width:700.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 345px !important;
    }
}

@media screen and (min-width:701px) and (max-width:720.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 355px !important;
    }
}

@media screen and (min-width:721px) and (max-width:750.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 375px !important;
    }
}

@media screen and (min-width:751px) and (max-width:800.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:801px) and (max-width:850.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:851px) and (max-width:900.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 335px !important;
    }
}

@media screen and (min-width:901px) and (max-width:950.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 325px !important;
    }
}

@media screen and (min-width:951px) and (max-width:991.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:992px) and (max-width:1023.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 320px !important;
    }
}

@media screen and (min-width:1024px) and (max-width:1100.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:1101px) and (max-width:1130.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:1131px) and (max-width:1150.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 330px !important;
    }
}

@media screen and (min-width:1151px) and (max-width:1200.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 340px !important;
    }
}

@media screen and (min-width:1201px) and (max-width:1250.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 320px !important;
    }
}

@media screen and (min-width:1251px) and (max-width:1300.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 320px !important;
    }
}

@media screen and (min-width:1300px) and (max-width:1400.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 350px !important;
    }
}

@media screen and (min-width:1401px) and (max-width:1450.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 350px !important;
    }
}

@media screen and (min-width:1451px) and (max-width:1500.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 350px !important;
    }
}

@media screen and (min-width:1501px) and (max-width:1550.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 350px !important;
    }
}

@media screen and (min-width:1551px) and (max-width:1600.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 360px !important;
    }
}

@media screen and (min-width:1601px) and (max-width:1650.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 360px !important;
    }
}

@media screen and (min-width:1651px) and (max-width:2000.7px)  {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 370px !important;
    }
}

@media screen and (min-width:1981px) and (max-width:2500.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 390px !important;
    }
}

/* @media screen and (min-width:2051px) and (max-width:2120.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 30px !important;
        left: 670px !important;
    }
}

@media screen and (min-width:2121px) and (max-width:2180.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 40px !important;
        left: 690px !important;
    }
}

@media screen and (min-width:2181px) and (max-width:2250.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 40px !important;
        left: 710px !important;
    }
}

@media screen and (min-width:2251px) and (max-width:2320.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 40px !important;
        left: 730px !important;
    }
}

@media screen and (min-width:2321px) and (max-width:2400.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 40px !important;
        left: 750px !important;
    }
}

@media screen and (min-width:2401px) and (max-width:2500.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 50px !important;
        left: 800px !important;
    }
} */

@media screen and (min-width:2501px)  {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 20px !important;
        left: 390px !important;
    }
}

/* @media screen and (min-width:2601px) and (max-width:2700.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 50px !important;
        left: 850px !important;
    }
}

@media screen and (min-width:2701px) and (max-width:2800.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 50px !important;
        left: 880px !important;
    }
}

@media screen and (min-width:2801px) and (max-width:2900.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 50px !important;
        left: 900px !important;
    }
}

@media screen and (min-width:2901px) and (max-width:3000.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 50px !important;
        left: 940px !important;
    }
}

@media screen and (min-width:3001px) and (max-width:3250.7px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 50px !important;
        left: 1000px !important;
    }
}

@media screen and (min-width:3251px) {
    body .uwy.userway_p3 .uai,
    body .uwy.userway_p6 .uai,
    body .uwy.userway_p7 .uai, 
    body .uwy.userway_p8 .uai {
        top: 50px !important;
        left: 1500px !important;
    }
} */