.home-btn {
    background: #180d3a;
    border: 1px solid #fff;
    border-radius: 54.971px;
    padding: 10px 20px;
    margin-bottom: 2rem;
}

.home-btn:hover {
    background: #180d3a;
    border: 1px solid #fff;
    border-radius: 54.971px;
    padding: 10px 20px;
}